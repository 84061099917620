import React from 'react'
import { Box, Typography, Chip, Divider } from "../../../imports/muiImport";
import RedeemableProduct from '../redeemableProducts/RedeemableProduct';
import ProductDetailPara from './ProductDetailPara';
import { useSelector } from 'react-redux';

function MobileProductDetails({ product }) {
    const PRODUCTDETAILS = useSelector((state) => state.ProductDetail?.allData);
   
    return (
        <Box mt={3} sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "100%",
        }}>
            <Typography variant='h1'
                sx={{
                maxWidth: "70%",
                color: "primary.main",
                fontSize: "4.5vw",
                fontWeight: 500,
                textAlign: "center",
                wordWrap: "break-word",
                }}
            >
                {product?.title}
            </Typography>

            {/* // ! = true */}

            <Chip label={`${PRODUCTDETAILS?.discount}% ${PRODUCTDETAILS?.productType}`} sx={{
                mt: 2,
                px: "4px",
                backgroundColor: "primary.main",
                color: "white.main",
                fontSize: "3.8vw",
                boxShadow: 6,
            }} />

            <Typography variant='subtitle1' sx={{
                mt: 2,
                color: "textPrimary.main",
                fontSize: "3.1vw",
            }}>
                *Terms & Condtions apply
            </Typography>

            <Box sx={{
                mt: 2,
                py: 2,
                backgroundColor: "white.main",
                // backgroundColor: "transparent",
                minHeight: 190,
                minWidth: "100%",
                borderRadius: 3,
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <Typography variant='body1' sx={{
                    mb: 1,
                    color: "primary.main",
                    fontSize: "4.1vw",
                    fontWeight: 600,
                }}>
                    Redeemable at:
                </Typography>
                <RedeemableProduct store={product?.store} website={product?.website} />
                <Box sx={{
                    mt: 2,
                    minWidth: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <Divider orientation="horizontal" sx={{
                        minWidth: "90%",
                        borderColor: "textPrimary.main",
                    }} />
                </Box>

                <ProductDetailPara productDescription={product?.description} />
            </Box>

            {product?.terms?.length > 0 &&
                (
                    <Box sx={{
                        mt: 2,
                        py: 2,
                        px: 1,
                        backgroundColor: "white.main",
                        // backgroundColor: "transparent",
                        minHeight: 190,
                        minWidth: "90%",
                        borderRadius: 3,
                    }}>
                        <Typography variant='subtitle1' sx={{
                            mb: 1,
                            color: "primary.main",
                            fontSize: "4.1vw",

                        }}>
                            Terms & Condtions
                        </Typography>

                        <Box
                            component="pre"
                            dangerouslySetInnerHTML={{ __html: product?.terms }}
                            style={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                overflowX: "auto",
                                maxWidth: "100%",
                                boxSizing: "border-box",
                                wordSpacing: "0px",
                                textIndent: "revert-layer",
                                fontFamily: "Celias",
                            }}
                            sx={{
                                mt: 1,
                                fontSize: "13.5px",
                                lineHeight: 1.5,
                                color: "textPrimary.main",
                                fontWeight: 400,
                                wordSpacing: "1px",
                                wordWrap: "break-word",
                                margin: "0 0 1em 0",
                                letterSpacing: "0.5px",
                                overflowWrap: "anywhere",
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }}
                        />
                    </Box>
                )
            }

            {product?.redeem?.length > 0 &&
                (
                    <Box sx={{
                        mt: 2,
                        mb: 4,
                        py: 2,
                        px: 1,
                        backgroundColor: "white.main",
                        // backgroundColor: "transparent",
                        minHeight: 190,
                        minWidth: "90%",
                        borderRadius: 3,
                    }}>
                        <Typography variant='subtitle1' sx={{
                            mb: 1,
                            color: "primary.main",
                            fontSize: "4.1vw",

                        }}>
                            How to Redeem
                        </Typography>

                        <Box
                            component="pre"
                            dangerouslySetInnerHTML={{ __html: product?.redeem }}
                            style={{
                                whiteSpace: "pre-wrap",
                                wordWrap: "break-word",
                                overflowX: "auto",
                                maxWidth: "100%",
                                boxSizing: "border-box",
                                wordSpacing: "0px",
                                textIndent: "revert-layer",
                                fontFamily: "Celias",
                            }}
                            sx={{
                                mt: 1,
                                fontSize: "13.5px",
                                lineHeight: 1.5,
                                color: "textPrimary.main",
                                fontWeight: 400,
                                wordSpacing: "1px",
                                wordWrap: "break-word",
                                margin: "0 0 1em 0",
                                letterSpacing: "0.5px",
                                overflowWrap: "anywhere",
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }}
                        />
                    </Box>
                )
            }

            {product?.['moreInfo']?.length > 0 &&
                (
                    <Box sx={{
                        mt: 2,
                        mb: 4,
                        py: 2,
                        px: 1,
                        backgroundColor: "white.main",
                        // backgroundColor: "transparent",
                        minHeight: 190,
                        minWidth: "90%",
                        borderRadius: 3,
                    }}>
                        <Typography variant='subtitle1' sx={{
                            mb: 1,
                            color: "primary.main",
                            fontSize: "4.1vw",

                        }}>
                            More Info
                        </Typography>

                        <Box
                            component="pre"
                            dangerouslySetInnerHTML={{ __html: product?.['moreInfo'] || null }}
                            style={{
                                whiteSpace: "initial",
                                wordWrap: "break-word",
                                overflowX: "auto",
                                maxWidth: "100%",
                                boxSizing: "border-box",
                                wordSpacing: "0px",
                                textIndent: "revert-layer",
                                fontFamily: "Celias",
                            }}
                            sx={{
                                mt: 1,
                                fontSize: "13.5px",
                                lineHeight: 1.5,
                                color: "textPrimary.main",
                                fontWeight: 400,
                                wordSpacing: "1px",
                                wordWrap: "break-word",
                                margin: "0 0 1em 0",
                                letterSpacing: "0.5px",
                                overflowWrap: "anywhere",
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                            }}
                        />
                    </Box>
                )
            }

        </Box>
    )
}

export default MobileProductDetails