import { Grid, Typography } from '@mui/material';


const CompanyPortfolio = () => {
    const companyPortfolio = [
        {
            total: "125K+",
            // title: "total no. of Customers",
            title: "Happy Customers",
        },
        {
            total: "14L+",
            title: "Complete Orders",
        },
        {
            total: "4.5",
            title: "Rating",
        },
        {
            total: "250+",
            // title: "total no. of Brands",
            title: "Brands",
        }
    ];

    return (
        <Grid

            container
            // xs={12}
            sx={{
                display: "flex",
                justifyContent: {
                    sm: "space-around",
                    lg: "center"
                },
            }}
        >

            {companyPortfolio.map((cItem, index) => (
                <Grid
                    key={index * 1}
                    item
                    xs={5}
                    sm={3}
                    md={3}
                    lg={2}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: { xs: 2, sm: 0, lg: 0 },
                        minHeight: { xs: "120px", sm: "100px", lg: "auto" },
                        maxWidth: { xs: "100%", sm: "200px", md: "270px", lg: "100%" },
                        my: { xs: "4px", sm: 0, lg: 0 },
                        mx: { xs: "4px", sm: 0, lg: 0 },
                        backgroundColor: { xs: "#00286514", sm: "#002865", lg: "transparent" },
                        transition: {
                            lg: "transform 0.2s linear",
                        },
                        "&:hover": {
                            boxShadow: { xs: 0, sm: 5, lg: 0 },
                            cursor: "default",
                            transform: {
                                lg: "scale(1.2)",
                            }
                        }
                    }}
                >
                    <Typography
                        variant="h3"
                        color="primary.main"
                        sx={{
                            textTransform: "capitalize",
                            fontSize: { xs: "2rem", sm: "1.6rem", md: "2.8rem" },
                            fontWeight: { xs: 500, sm: 400, lg: 500 },
                            color: {
                                xs: "primary.main",
                                sm: "white.light",
                                lg: "primary.main",
                            }
                        }}
                    >
                        {cItem?.total}
                    </Typography>

                    <Typography
                        variant="h6"
                        sx={{
                            textTransform: "capitalize",
                            fontSize: { xs: ".7rem", sm: "1.3rem", md: "1rem" },
                            fontWeight: { xs: 400, sm: 500 },
                            color: {
                                xs: "dark.main",
                                sm: "white.light",
                                lg: "dark.main",
                            }
                        }}
                    >
                        {cItem?.title}
                    </Typography>

                </Grid>

            ))}

        </Grid>
    )
}

export default CompanyPortfolio