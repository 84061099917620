import React, { useEffect, useState } from 'react';
import { Box, Container } from '../../../imports/muiImport';
import { useLocation } from 'react-router-dom';
import Slider from 'react-slick';

const banner = [
    {id: 1, cid: 'apparel', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Apparel.jpg", bgcolor: "#FF5733"},
    { id: 2, cid: 'footwear', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Footwear.jpg", bgcolor: "#33FF57" },
    { id: 3, cid: 'e-commerce', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/E-Commerce.jpg", bgcolor: "#5733FF" },
    { id: 4, cid: 'accessories', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Accessories.jpg", bgcolor: "#FFC300" },
    { id: 5, cid: 'entertainment', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Entertainment.jpg", bgcolor: "#C70039" },
    { id: 6, cid: 'food', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Food.jpg", bgcolor: "#900C3F" },
    { id: 7, cid: 'gifting', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Gifting.jpg", bgcolor: "#581845" },
    { id: 8, cid: 'travel', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Travel.jpg", bgcolor: "#1ABC9C" },
    { id: 9, cid: 'ott', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/OTT.jpg", bgcolor: "#3498DB" },
    { id: 10, cid: 'mobile--electronics', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Mobile.jpg", bgcolor: "#9B59B6" },
    { id: 11, cid: 'jewellery', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Jewellery.jpg", bgcolor: "#F1C40F" },
    { id: 12, cid: 'health--wellness', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Health+and+Wellness.jpg", bgcolor: "#E74C3C" },
    { id: 13, cid: 'grocery-and-home-needs', bannerImage: "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/Grocery+and+Home+Needs.jpg", bgcolor: "#2ECC71" },
];

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
};

const ProductsBanner = () => {
    const [selectedCategoryBanner, setSelectedCategoryBanner] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const categoryParam = searchParams.get('category');

        if (categoryParam) {
            const userSelectedCategory = categoryParam ? categoryParam?.split('+').map((item) => item?.trim()) : [];
            const newSelectedCate = userSelectedCategory?.[0]?.split(" ");

            // Filter banners based on the selected categories
            const filteredCategory = banner?.filter((item) => newSelectedCate.includes(item.cid));
            setSelectedCategoryBanner(filteredCategory);
        } else {
            setSelectedCategoryBanner(banner);
        }
    }, [location]);


    return (
        <Container
            maxWidth="false"
            disableGutters
            sx={{ px: 1 }}
            className="slider-container"
        >

            {selectedCategoryBanner?.length === 1 ?
                (
                    <Box
                        key={`category-slider-${selectedCategoryBanner?.[0]?.id}`}
                        component="img"
                        src={selectedCategoryBanner?.[0]?.bannerImage || "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/CCm4HfudrT7b6WsUCfU5GGAyZdJfRaIJc6T5Byq2.png"}
                        sx={{
                            width: '100%',
                            minWidth: "100%",
                            height: {
                                xs: 130,
                                sm: 190,
                                lg: 280
                            },
                            backgroundColor: "#fff",
                            borderRadius: 2,
                        }}
                    />
                )
                :
                (
                    <Slider {...settings}>
                        {selectedCategoryBanner.map((category) => (
                            <Box
                                key={`category-slider-${category.id}`}
                                component="img"
                                src={category.bannerImage || "https://99paisa.s3.ap-south-1.amazonaws.com/fund--request/CCm4HfudrT7b6WsUCfU5GGAyZdJfRaIJc6T5Byq2.png"}
                                sx={{
                                    width: '100%',
                                    minWidth: "100%",
                                    height: {
                                        xs: 130,
                                        sm: 190,
                                        lg: 280
                                    },
                                    backgroundColor: "#fff",
                                    borderRadius: 2,
                                }}
                            />
                        ))}
                    </Slider>
                )
            }
        </Container >
    );
};

export default ProductsBanner;
