import { React, lazy, Suspense, Navigate, Route, createBrowserRouter, createRoutesFromElements, Fragment } from "../imports/generalImport";
import MobileDesignLayout from "../mobile/mobileLayout/MobileDesignLayout";
import MobileHomePage from "../mobile/mobilePages/mobileHomePage/MobileHomePage";
import MobileHomeProductDetails from "../mobile/mobilePages/mobileHomePage/MobileHomeProductDetails";
import Thankyou from "../pages/OTHERPAGE/Thankyou";
import PageNotFound from "../pages/OTHERPAGE/PageNotFound";
import MobileSearch from "../mobile/mobilePages/MobileSearch";
import MobileAllProducts from "../mobile/mobilePages/MobileAllProducts";
import MobileNotifications from "../mobile/mobilePages/MobileNotications";
import MobileProfile from "../mobile/mobilePages/MobileProfile";
import MobileContactUs from "../mobile/mobilePages/MobileContactUs";
import MobileOrders from "../mobile/mobilePages/MobileOrders";
import MobileLogin from "../mobile/mobilePages/MobileLogin";
import PageLoading from "../PageLoading";
import MobileSignup from "../mobile/mobilePages/MobileSignup";
import MobileUserTransactions from "../mobile/mobilePages/MobileUserTransactions";
import AboutPage from "../pages/ABOUTUS";
import Blogpage from "../pages/BLOGS";
import GiftCardWorks from "../pages/GIFTCARDSWORKS";
import PrivacyPolicy from "../pages/PRIVACYPOLICY";
import CropPolicy from "../pages/CORPPOLICY";
import MobileForgotPassword from "../mobile/mobilePages/MobileForgotPassword";
import { AccountDelete } from "../pages/DELETE/AccountDelete";
const AuthenticateUser = lazy(() => import("./AuthticateUser"));
const ProfileLayout = lazy(() => import("../layouts/ProfileLayout/"));
const TermsAndCondition = lazy(() => import("../pages/TERMSANDCONDITION"));
const BlogDetail = lazy(() => import("../pages/BLOGDETAILS"));
const CropPage = lazy(() => import("../pages/CORP"));


const basename = process.env.REACT_APP_ENDPOINT || '/';

export const mobileRouter = createBrowserRouter(
    createRoutesFromElements(
        <Fragment>
            {/* Main layout for mobile */}
            <Route path="/" element={<MobileDesignLayout />}>
                <Route index element={<MobileHomePage />} />
                <Route path="card-list/:category?/*?" element={<MobileAllProducts />} />
                <Route path="card-details/:id/:title?" element={<MobileHomeProductDetails />} />
                <Route path="search" element={<MobileSearch />} />

                <Route path="contact-us" element={<Navigate to="/contactus" replace />} />
                <Route path="contactus" element={<MobileContactUs />} />

                <Route path="aboutus" element={<AboutPage isMobile={true} />} />
                <Route path="blog" element={<Blogpage isMobile={true} />} />
                <Route path="blog-details/:title" element={<BlogDetail isMobile={true} />} />
                <Route path="how-gift-card-work" element={<GiftCardWorks isMobile={true} />} />
                <Route path="privacypolicy" element={<PrivacyPolicy isMobile={true} />} />
                <Route path="corppolicy" element={<CropPolicy isMobile={true} />} />
                <Route path="termsandcondition" element={<TermsAndCondition isMobile={true} />} />
                <Route path="corporates" element={<CropPage />} />

                <Route path="thank-you" element={<Thankyou />} />
                <Route path="*" element={<Navigate to="/page-not-found" replace />} />
                <Route path="page-not-found" element={<PageNotFound />} />
            </Route>

            {/* Profile layout */}
            <Route
                path="/profile/*"
                element={
                    <Suspense fallback={<PageLoading />}>
                        <ProfileLayout />
                    </Suspense>
                }
            >
                <Route index element={<MobileProfile />} />
                <Route path="order" element={<MobileOrders />} />
                <Route path="notifications" element={<MobileNotifications />} />
                <Route path="statement" element={<MobileUserTransactions />} />
                <Route path="account-delete" element={<AccountDelete />} />
                <Route path="*" element={<Navigate to="/profile" replace />} />
            </Route>

            <Route path="/"
                element={
                    <Suspense fallback={<PageLoading />}>
                        <AuthenticateUser />
                    </Suspense>
                }
            >
                <Route path="login" element={<MobileLogin />} />
                <Route path="signup" element={<MobileSignup />} />
                <Route path="password-reset" element={<MobileForgotPassword />} />
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Fragment>
    ), { basename }
);
