import React from 'react';
import { Box, Button } from "../../../imports/muiImport";
import { useNavigate } from "../../../imports/generalImport";
import DeleteIcon from '@mui/icons-material/Delete';

function MobileAccountDeleteButton({ py = 2.5 }) {
    const navigate = useNavigate();
    const handleUserLogout = () => {
        navigate("/profile/account-delete", { replace: true })
    };

    return (
        <Box sx={{ width: "100%", border: "1px dashed #d22018", borderRadius: 2 }}>
            <Button
                type='submit'
                variant='contained'
                fullWidth
                onClick={handleUserLogout}
                size="large"
                startIcon={<DeleteIcon />}
                sx={{
                    py: py,
                    borderRadius: 2,
                    color: "#fb0000cf",
                    fontWeight: 600,
                    fontSize: "3.4vw",
                    backgroundColor: "#ff000045",
                    textTransform: "capitalize",
                    "&:hover": {
                        color: "#fb0000cf",
                        backgroundColor: "#ff000045",
                    }
                }}
            >
                Account Delete
            </Button>
        </Box>
    );
}

export default MobileAccountDeleteButton