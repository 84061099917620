import React, { useEffect, useState } from 'react';
import MobileProductCategoryTabs from '../mobileComponents/mobileAllProducts/MobileProductCategoryTabs';
import MobileProductSection from '../mobileComponents/mobileProductCard/MobileProductSection';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Container, Grid } from '@mui/material';
import { categoryUrl } from '../../shared/extra';
import { Helmet } from 'react-helmet-async';
import ProductsBanner from '../../pages/ALLGIFTCARD/LARGEDEVICE/ProductsBanner';

function MobileAllProducts() {
    const [currentProductCategory, setCurrentProductCategory] = useState('apparel');
    const [selectedCategory, setSelectedCategory] = useState([]);
    const productCategories = useSelector((state) => state.productByCategories.allData);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const categoryParam = searchParams.get('category');

    useEffect(() => {

        if (location?.pathname === '/card-list' && location?.search === '') {
            navigate('/card-list?category=apparel');
        }

        setCurrentProductCategory(categoryParam);
        const filteredData = productCategories?.filter(item => categoryUrl(item.category) === categoryParam);
        setSelectedCategory(filteredData?.[0]?.products)
    }, [location.search, productCategories]);

    return (
        <>
            <Helmet>
                <title>Explore the Best Gift Cards in India – Shop, Redeem & Enjoy!</title>
                <meta name="description" content="Discover a wide range of gift cards from top brands on 99Gift. From shopping and dining to entertainment and lifestyle, we offer the perfect gift for any occasion" />
                <meta property="og:title" content="Explore the Best Gift Cards in India – Shop, Redeem & Enjoy!" />
                <meta property="og:description" content="Discover a wide range of gift cards from top brands on 99Gift. From shopping and dining to entertainment and lifestyle, we offer the perfect gift for any occasion" />
            </Helmet>

            <Container
                disableGutters
                sx={{ bgcolor: '#fefafe' }} >
                <Grid container sx={{
                    mb: {
                        sm: 3,
                        lg: 0
                    }
                }}>
                    <ProductsBanner />
                </Grid>
                <MobileProductCategoryTabs currentProductCategory={currentProductCategory} />
                <Divider />
                <MobileProductSection products={selectedCategory} />
            </Container>
        </>
    );
}

export default MobileAllProducts;