import React from 'react';
import { Box } from "../../../imports/muiImport";
import { christmasProductSantaIcon } from "../../../imports/generalImport";

function MobileProductDetailImage({ image }) {
    return (
        <Box 
            component={"div"}
            sx={{
                py:2,
                width:"100%",
                aspectRatio: 16 / 9,
                overflow: "hidden",
                display:"flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >

            <Box
                component={'img'}
                src={image}
                width={"100%"}  //old - correct change only for holi
                height={'100%'} //old - correct change only for holi
                // width={312}  //old - correct change only for holi
                // height={170} //old - correct change only for holi
                // width={230}
                // height={160}
                sx={{
                    maxWidth: "75vw",
                    boxShadow: 4,
                    borderRadius: 4,
                    objectFit: 'cover',
                }} />
        </Box>
    )
}

export default MobileProductDetailImage;