import React from 'react'
import { Box, Chip, Divider, List, ListItem, ListItemAvatar, ListItemText } from "../../../imports/muiImport";
import { titleToUrl } from '../../../shared/extra';
import { isDiscountedProduct } from "../../../hooks/utilsFn/utilsFn"
import { NavLink } from 'react-router-dom';

function MobileProductSearchResult({ searchResult }) {
    return (
        <List sx={{ width: '100%', bgcolor: '#EEF7FF', borderRadius: 2, }}>

            {searchResult?.map((item, index) => {
                const { productType, discount } = isDiscountedProduct(
                    item?.discount,
                    item?.cashback_amount
                );

                return (
                    <React.Fragment key={`search-result-${item?.id}`}>
                        <NavLink
                            to={`/card-details/${item?.id}/${titleToUrl(item?.title)}`}
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <ListItem >
                                <ListItemAvatar >
                                    <Box
                                        component='img'
                                        src={item?.image}
                                        width={80}
                                        height={50}
                                        sx={{
                                            objectFit: "cover",
                                            boxShadow: 6,
                                            borderRadius: 2,
                                            mr: 2,
                                        }}
                                    />

                                </ListItemAvatar>
                                <ListItemText
                                    primary={item?.title}
                                    secondary={
                                        <Chip label={`${productType} : ${discount}%`}
                                            sx={{ bgcolor: "#D1E9F6", color: "#03346E", fontWeight: 600, fontSize: "11.5px" }} />
                                    }
                                    sx={{
                                        color: "primary.main",
                                        textDecoration: "none",
                                    }}
                                    primaryTypographyProps={{
                                        sx: {
                                            color: "primary.main",
                                            fontWeight: 500,
                                            fontSize: "13.5px"
                                        },
                                    }}
                                />
                            </ListItem>
                        </NavLink>
                        {index < searchResult?.length - 1 && (
                            <Divider variant="inset" component="li" />
                        )}
                    </React.Fragment>
                );
            })}

        </List>
    )
}

export default MobileProductSearchResult