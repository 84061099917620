import * as actionTypes from './actionTypes';
import toast from 'react-hot-toast';
import { fetchAllNotifications } from '../../apiCalls/urls/urlFunction';


export const onFetchNotification = (payload) =>  {
    return async (dispatch) => {
        const notificationResponse = await fetchAllNotifications(payload)

        if (notificationResponse?.status) {
            dispatch(updateNotificationReducer(notificationResponse?.data))
        }
        else {
            toast.error(notificationResponse?.message)
        }
    }
}

export const updateNotificationReducer = (data) => {
    return {
        type: actionTypes.UPDATE_NOTIFICATION_REDUCER,
        payload: { data }
    };
}