import { combineReducers } from "redux";
// Public Pages Reducers
import AllProducts from "../layouts/innerComponent/AllProducts/reducer";
import Category from "../layouts/innerComponent/NavarCategories/reducer";
import BestSeller from "./bestSeller/reducer";
import FeaturedProducts from "./featuredProducts/reducer";
import TopFiveProducts from "./topFive/reducer";
import EntertainmentProducts from "./EntertainmentProducts/reducer";
import SlidersData from "./fetchSlider/reducer";
import SelectedCategory from "./categorySelect/reducer";
import Denominations from "./productDetails/reducer";
import ProductDetail from "./productInfo/reducer";
import UserBalance from "./userBalance/reducer";
import productByCategories from "./productsByCategory/reducer";
import blogs from "./blogs/reducer";

// Dashboard Pages Reducers
import Card from "../pages/profileDashboard/viewCard/reducers";
import Notification from "./notification/reducer";
import UserOrder from "./userOrders/reducer";
import UserStatement from "./userStatement/reducer";
import UserProfile from "./userProfile/reducer";
import Analytic from "./analytics/reducer";

const rootReducer = combineReducers({
  AllProducts,
  Category,
  BestSeller,
  FeaturedProducts,
  TopFiveProducts,
  EntertainmentProducts,
  SlidersData,
  SelectedCategory,
  Denominations,
  ProductDetail,
  UserBalance,
  productByCategories,
  blogs,
  Analytic,
  Card,
  Notification,
  UserOrder,
  UserStatement,
  UserProfile,
});

export default rootReducer;
