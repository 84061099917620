import React from 'react'
import { useSelector } from "../../../imports/generalImport";
import MobileHomeCategory from '../../mobileComponents/mobileHomeCategory/MobileHomeCategory'
import MobileProductSection from '../../mobileComponents/mobileProductCard/MobileProductSection'
import HeroSlider from "../../../components/homeSlider";
import { Container } from '../../../imports/muiImport';
import MobileHomeSearch from '../../mobileComponents/mobileHomeSearch/MobileHomeSearch';
import HomePageTextDetails from '../../../components/textContent/giftBuyGuide';
import { Helmet } from 'react-helmet-async';

function MobileHomePage() {
    const bestSellerData = useSelector((state) => state.BestSeller.allData);
    const topFiveData = useSelector((state) => state.TopFiveProducts.allData);

    return (
        <>
            <Helmet>
                <title>Gift Cards: Discounted eGift Cards for 250+ Brands in India</title>
                <meta name="description" content="99Gift offers a wide variety of discounted gift cards for over 250 brands in India, including Apparels, eCommerce, Entertainment, Travels, Food, Entertainment, Grocery, Mobile, Footwear Etc. You can also find E-Gift cards for personal and commercial use" />
                <meta property="og:title" content="99Gift - 200+ Brands Discount Gift Vouchers Online" />
                <meta property="og:description" content="99Gift offers a wide variety of discounted gift cards for over 250 brands in India, including Apparels, eCommerce, Entertainment, Travels, Food, Entertainment, Grocery, Mobile, Footwear Etc. You can also find E-Gift cards for personal and commercial use" />
            </Helmet>

           <Container
                disableGutters
                sx={{
                    bgcolor: '#fefafe',                    
                 }} >
                <MobileHomeSearch />
                <MobileHomeCategory />
                <Container sx={{ borderRadius: 4, mb: 4, }}>
                    <HeroSlider dots={false} height={160} />
                </Container>
                <MobileProductSection title={'Hot Deals and Best Sellers'} products={bestSellerData} />
                <MobileProductSection title={topFiveData?.[0]?.['title']} products={topFiveData?.[0]?.['products']} />
                <MobileProductSection title={topFiveData?.[1]?.['title']} products={topFiveData?.[1]?.['products']} />
                <MobileProductSection title={topFiveData?.[2]?.['title']} products={topFiveData?.[2]?.['products']} />
                <HomePageTextDetails />
            </Container>
        </>
    )
}

export default MobileHomePage