

export const isDiscountedProduct = (discount, cashback_amount) => {
    if (discount === '0' && !cashback_amount ) {
        return {
            productType: "Discount",
            discount: discount
        }
    } else if (discount == '0' && cashback_amount > 0) {
        return ({
            productType: "Cashback",
            discount: cashback_amount
        });
    } else {
        return {
            productType: "Discount",
            discount: discount
        }
    }
    
}