import React, { useState } from "react";
import {
    Card, CardContent, Typography, TextField, Button,
    Box, Alert, CircularProgress, Backdrop
} from "@mui/material";
import axios from "axios";
import { userAccountDelete } from "../../apiCalls/urls/urlFunction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../apiCalls/functions";
import { useAuth } from "../../hooks/contexts/AuthContext";

export const AccountDelete = () => {
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const [apiResponse, setApiResponse] = useState({ type: "", message: "" });
    const [loading, setLoading] = useState(false);

    const userProfile = useSelector((state) => state?.UserProfile?.allData?.data);
    const userId = 123;
    // const userId = userProfile?.id || 123;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
            setAuthUser,
            setIsLoggedIn
        } = useAuth();

    const handleDelete = async () => {
        if (reason.length < 10) {
            setError("Reason must be at least 10 characters long.");
            return;
        }

        setError("");
        setApiResponse({ type: "", message: "" });
        setLoading(true);

        try {
            const response = await userAccountDelete({
                user: { id: userId },
                reason,
            });

            if (response.status) {
                setApiResponse({ type: "success", message: "Your account has been deleted successfully." });
                userLogout();
                setAuthUser(null);
                setIsLoggedIn(false);
                navigate("/", { replace: true });
            } else {
                setApiResponse({ type: "error", message: response.data.message || "Failed to delete account." });
            }
        } catch (err) {
            setApiResponse({ type: "error", message: err.response?.data?.message || "Something went wrong." });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {/* Backdrop that covers the entire screen */}
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Card sx={{ mb: 3, backgroundColor: "white.main", boxShadow: 0, p: 2 }}>
                <CardContent>
                    <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Delete Account
                    </Typography>

                    {/* Show different types of alerts */}
                    {apiResponse.message && (
                        <Alert severity={apiResponse.type} sx={{ mb: 2 }}>
                            {apiResponse.message}
                        </Alert>
                    )}

                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        label="Reason for deleting your account"
                        variant="outlined"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                        error={!!error}
                        helperText={error}
                        sx={{ mb: 2 }}
                    />

                    <Box textAlign="right">
                        <Button
                            variant="contained"
                            color="error"
                            disabled={reason.length < 10 || loading}
                            onClick={handleDelete}
                        >
                            Delete Account
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};
