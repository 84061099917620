import * as actionTypes from './actionTypes';
import { fetchAnalytic } from '../../apiCalls/urls/urlFunction';


export function onFetchDashboardData() {
    return async (dispatch) => {
        let result = await fetchAnalytic();
        if (result.status) {
            dispatch(updateDashboardDataReducer(result.data))
        }
    }
}

export const updateDashboardDataReducer = (data) => {
    return {
        type: actionTypes.UPDATE_DASHBOARD_DATA_REDUCER,
        payload: { data }
    };
}